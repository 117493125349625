import React from 'react'
import {
    Link
  } from "react-router-dom";


function htmlDecode(input){
    var e = document.createElement('textarea');
    e.innerHTML = input;
    return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
}


class ArchiveProdotto extends React.Component {
    constructor(){
        super()
        this.state = {
            posts: [],
            totalCount: 0,
            pages: 0,
            perPage: 12,
            currentPage: 1
        }
    }

    componentDidMount(){
        fetch('https://romanogazzera.it/wp-json/wp/v2/opera?per_page='+this.state.perPage)
            .then(response => {
                const totalCount  = response.headers.get( 'x-wp-total' );
                const pages = response.headers.get( 'x-wp-totalpages' );
                this.setState({totalCount: totalCount, pages: pages});
                return response.json()
            })
            .then(response => {
                //console.log(response)
                this.setState({posts: response})
            })
    }



    render() {
        const posts = this.state.posts
        return (
            <div>
                <h1>Opere</h1>
                <p>
                Questo è l'archivio opere
                </p>
                <ul className="archiveOpere">
                    {posts.length < 1 ?
                    'Caricamento opere...' :
                    posts.map((post, i) => {     
                        return (
                            <Link to={`opera/${post.slug}`}>
                                <li key={post.id}>
                                    <img src={post.opera_meta.immagine} alt="" />
                                    <p>{htmlDecode(post.title.rendered)}</p>
                                </li>
                            </Link>
                        ) 
                    })}
                </ul>
                <p className="pagination">Pagina {this.state.currentPage} di {this.state.pages} - {this.state.currentPage * this.state.perPage} opere di {this.state.totalCount}</p>
                
            </div>
        )
    }

}

export default ArchiveProdotto