import React from 'react'

function Home() {
    return (
        <header>
            <h1>Home</h1>
            <p>
            This is the homepage template
            </p>
        </header>
    )
}

export default Home