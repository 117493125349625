import React from 'react'

function Users() {
    return (
        <header>
            <h1>Users</h1>
            <p>
            This is the users page
            </p>
        </header>
    )
}

export default Users