import React from 'react'

function About() {
    return (
        <header>
            <h1>About</h1>
            <p>
            This is the about page
            </p>
        </header>
    )
}

export default About