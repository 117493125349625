import React, {useState, useEffect} from 'react'
import { useLocation } from "react-router-dom";

function htmlDecode(input){
    var e = document.createElement('textarea');
    e.innerHTML = input;
    return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
}

function SingleProdotto() {
    let location = useLocation();
    const pathname = location.pathname
    const urlSlug = pathname.replace('/opera/','')
    const [ posts, setPosts ] = useState([])

    useEffect(() => {
        fetch('https://romanogazzera.it/wp-json/wp/v2/opera?slug=' + urlSlug)
            .then(response => response.json())
            .then(response => {
                setPosts(response)
            })
    }, [urlSlug])

    return (
        <header className="singleOpera">
            <h1>{posts[0] ? htmlDecode(posts[0].title.rendered) : 'caricamento opera...'}</h1>
            <p>
            Questo è il template per mostrare il singolo prodotto
            </p>
            <img src={posts[0] ? posts[0].opera_meta.immagine : 'caricamento immagine...'}  alt="" />
        </header>
    )
}

export default SingleProdotto